let map;

//Hover Slider
if($(window).width() < 992) {
  $('.actor_item_slider').slick({
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    cssEase: 'linear'
  });
} else {
  $('.actor_item_slider').slick({
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 500,
    cssEase: 'linear'
  });
  function slickPause() {
    $('.actor_item_slider').slick('slickPause');
  }

  slickPause();

  $('.actor_item_slider').mouseover(function() {
    $(this).slick('slickPlay')
  });
  $('.actor_item_slider').mouseout(function() {
    slickPause();
  });

  $(window).scroll(function(){
    slickPause();
  });
}

// Profile Accordion
(function(){
	let d = document,
	accordionToggles = d.querySelectorAll('.js-accordionTrigger'),
	setAria,
	setAccordionAria,
	switchAccordion,
  touchSupported = ('ontouchstart' in window),
  pointerSupported = ('pointerdown' in window);

  skipClickDelay = function(e){
    e.preventDefault();
    e.target.click();
  }

		setAriaAttr = function(el, ariaType, newProperty){
		el.setAttribute(ariaType, newProperty);
	};
	setAccordionAria = function(el1, el2, expanded){
		switch(expanded) {
      case "true":
      	setAriaAttr(el1, 'aria-expanded', 'true');
      	setAriaAttr(el2, 'aria-hidden', 'false');
      	break;
      case "false":
      	setAriaAttr(el1, 'aria-expanded', 'false');
      	setAriaAttr(el2, 'aria-hidden', 'true');
      	break;
      default:
				break;
		}
	};
//function
switchAccordion = function(e) {
  console.log("triggered");
	e.preventDefault();
	let thisAnswer = e.target.parentNode.nextElementSibling;
	let thisQuestion = e.target;
	if(thisAnswer.classList.contains('is-collapsed')) {
		setAccordionAria(thisQuestion, thisAnswer, 'true');
	} else {
		setAccordionAria(thisQuestion, thisAnswer, 'false');
	}
  	thisQuestion.classList.toggle('is-collapsed');
  	thisQuestion.classList.toggle('is-expanded');
		thisAnswer.classList.toggle('is-collapsed');
		thisAnswer.classList.toggle('is-expanded');

  	thisAnswer.classList.toggle('animateIn');
	};
	for (let i=0,len=accordionToggles.length; i<len; i++) {
		if(touchSupported) {
      accordionToggles[i].addEventListener('touchstart', skipClickDelay, false);
    }
    if(pointerSupported){
      accordionToggles[i].addEventListener('pointerdown', skipClickDelay, false);
    }
    accordionToggles[i].addEventListener('click', switchAccordion, false);
  }
})();
//Talants Video
$('.talants_play').click(function() {
  video = '<video class="video" loop="loop" autoplay="" muted="" ><source src="https://denis-creative.com/wp-content/uploads/2018/01/video.mp4" type="video/mp4" /><source src="https://denis-creative.com/wp-content/uploads/2018/01/video.ogv" type="video/ogv" /><source src="https://denis-creative.com/wp-content/uploads/2018/01/video.webm" type="video/webm" /></video>';
  $('.talants_item_video').replaceWith(video);
});

// Event Slider
$(document).ready(function(){
  $('.event-slider-start').slick({
    dots: false,
    arrows: true,
    slidesToScroll: 1,
    slidesToShow: 2,
    nextArrow: '<i class="icon-slick-next slick_event_next"></i>',
    prevArrow: '<i class="icon-slick-prev slick_event_prev"></i>',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  });
});

$(document).ready(function(){
  $('.event_slider').slick({
    dots: false,
    arrows: true,
    slidesToScroll: 1,
    nextArrow: '<i class="icon-slick-next"></i>',
    prevArrow: '<i class="icon-slick-prev"></i>',
  });
});

let $slider = $('.event_slider');

if ($slider.length) {
  let currentSlide;
  let slidesCount;
  let sliderCounter = document.createElement('div');
  sliderCounter.classList.add('slider__counter');

  let updateSliderCounter = function(slick, currentIndex) {
    currentSlide = slick.slickCurrentSlide() + 1;
    slidesCount = slick.slideCount;
    $(sliderCounter).text(currentSlide + ' / ' + slidesCount)
  };

  $slider.on('init', function(event, slick) {
    $slider.append(sliderCounter);
    updateSliderCounter(slick);
  });

  $slider.on('afterChange', function(event, slick, currentSlide) {
    updateSliderCounter(slick, currentSlide);
  });
}


///////// EVENT CALENDAR ////////

function markEventDatesOnCalendar(date){
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  $.ajax({
    type: "GET",
    url: `/api/event/get-by-month?month=${month}&year=${year}`,
    success: function (result) {
      if (result){
        let calendar = $('.calendar-container');
        result.forEach((date) => {
          let day = calendar.find(`[data-date='${new Date(date)}']`);
          day.addClass('selected');
        });
      }
    },
    error: function () {
      console.log("Ooops... Occurred an error while getting events dates.");
    }
  })
}
